








import { Component, Vue, Watch, Prop } from "vue-property-decorator"

@Component({
	name: "Default"
})
export default class Default extends Vue {
	@Prop(String) infoTtile!: string
	@Prop(String) infoAbout!: string
	@Prop(String) infoImg!: string

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	created() {
		// console.log("created")
	}

	mounted() {
		// console.log("mounted")
	}
}
