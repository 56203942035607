








import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
	name: "Default"
})
export default class parts2 extends Vue {
	infoTtile: string = "品牌电器"
	infoAbout: string = "尽情享受生活"

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	created() {
		// console.log("created")
	}

	mounted() {
		// console.log("mounted")
	}
}
