










import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
	name: "Default"
})
export default class parts2 extends Vue {
	infoTtile: string = "家纺布艺"
	infoAbout: string = "给你一个舒适的家"

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	// created() {
	// }

	// mounted() {
	// }
}
