
















import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import parts1 from "./parts1.vue"

@Component({
	components: {
		parts1
	}
})
export default class styke1 extends Vue {
	@Prop(Array) specialZoneItems!: Array<any>
	@Prop(String) specialZoneName!: String
	// tableData: { infoTtile: String; infoAbout: String; infoImg: String }[] = [
	// 	{
	// 		infoTtile: "购物卡",
	// 		infoAbout: "沃尔玛、京东",
	// 		infoImg: ""
	// 	},
	// 	{
	// 		infoTtile: "视频会员",
	// 		infoAbout: "随时看大片",
	// 		infoImg: ""
	// 	}
	// ]
	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	// created() {
	// }

	// mounted() {
	// }
}
