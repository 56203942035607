




















































































































































































































































































// declare let weltH5Window: any
// new weltH5Window("APP-积分商城-贴边图标",1);
import { Swipe, SwipeItem } from "vant"
import { Component, Vue } from "vue-property-decorator"
import { Swiper as SwiperClass, Pagination, Autoplay, Navigation, EffectFade } from "swiper/core"
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter"
import "swiper/swiper-bundle.min.css"
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
SwiperClass.use([Pagination, Autoplay, Navigation, EffectFade])
import topNav from "../../components/topNav.vue"
import zoneStyle1 from "./components/zoneStyle/zoneStyle1.vue"
import zoneStyle2 from "./components/zoneStyle/zoneStyle2.vue"
import zoneStyle3 from "./components/zoneStyle/zoneStyle3.vue"
import zoneStyle4 from "./components/zoneStyle/zoneStyle4.vue"
import goodsList from "./components/goodsList/goodsList.vue"
import MescrollVue from "mescroll.js/mescroll.vue"
import { pushHistory } from "../../utils/pushHistory"
import { goGainIntegral } from "@/utils/common"
@Component({
	components: {
		[Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		Swiper,
		SwiperSlide,
		topNav,
		zoneStyle1,
		zoneStyle2,
		zoneStyle3,
		zoneStyle4,
		goodsList,
		MescrollVue,
	},
})
export default class Default extends Vue {
	updateOrIntegral = true
	isUpdate = false
	isIntegral = true
	isUpdateRotate = false
	isIntegralRotate = false
	isDefault = false
	swiperOption: any = {
		centeredSlides: true,
		slidesPerView: "auto",
		loop: true,
		autoplay: {
			delay: 2000,
			disableOnInteraction: false,
		},
		//滑动速度
		speed: 1000,
	}
	flashasleListSwipweOption: any = {
		slidesPerView: 3,
		freeMode: true,
		loop: false,
		direction: "vertical", // 设置为纵向
		// spaceBetween: 10,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		//滑动速度
		speed: 1000,
	}
	swiperList: Array<any> = []
	zoneData: Array<any> = []
	goodsListParams: any = {
		SortType: 0,
		Asc: 0,
	}
	goodsListData: Array<any> = []
	mescrollDown: any = {
		callback: this.mescrollData,
	}
	mescrollUp: any = {
		isBounce: false,
		use: false,
	}
	mescroll: any = null
	swiperListFirst: any = ""
	flashsalelist: Array<any> = []
	// $refs!: {
	// 	mescroll: HTMLFormElement
	// }

	created() {
		// this.getSwiperData()
		// this.specialzone()
		// this.getGoodsData()
	}
	mounted() {
		// 原位置
		const position: any = sessionStorage.getItem("position")
		if (Number(position) > 0) {
			setTimeout(() => {
				;(document.querySelector(".mescroll") as any).scrollTop = Number(position)
				console.log(position, document.querySelector(".mescroll"), "position")
				window.sessionStorage.setItem("position", "0")
			}, 500)
		}
	}
	beforeRouteLeave(to: any, from: any, next: any) {
		if (to.name == "goodsDetails") {
			const position: any = document.querySelector(".mescroll")?.scrollTop
			sessionStorage.setItem("position", position)
		}
		next()
	}

	//1、挂载完成后，判断浏览器是否支持popstate
	// mounted() {
	// 	console.log(window.history)
	// 	if ((window as any).history && window.history.pushState) {
	// 		history.pushState(null, "", document.URL)
	// 		window.addEventListener("popstate", this.goBack, false)
	// 	}
	// }

	// //页面销毁时，取消监听。否则其他vue路由页面也会被监听
	// destroyed() {
	// 	window.removeEventListener("popstate", this.goBack, false)
	// }

	// //3、将监听操作写在methods里面，removeEventListener取消监听内容必须跟开启监听保持一致，所以函数拿到methods里面写
	// goBack() {
	// 	const u: string = navigator.userAgent
	// 	const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
	// 	const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
	// 	if (u.indexOf("eshebao") != -1) {
	// 		if (isAndroid) {
	// 			;(window as any).eshebao.gotoBack()
	// 		} else if (isiOS) {
	// 			;(window as any).webkit.messageHandlers.eshebao.postMessage("gotoBack")
	// 		}
	// 	}
	// 	//replace替换原路由，作用是避免回退死循环
	// }

	// mounted() {
	// 	this.onpopstate()
	// }

	// destroyed() {
	// 	// 当页面销毁必须要移除这个事件，vue不刷新页面，不移除会重复执行这个事件
	// 	window.removeEventListener("popstate", this.closeViews, false)
	// }

	// onpopstate() {
	// 	pushHistory()
	// 	// 给window添加一个popstate事件，拦截返回键，执行this.closeViews事件，addEventListener需要指向一个方法
	// 	window.addEventListener("popstate", this.closeViews, false)
	// }

	// closeViews() {
	// 	const u: string = navigator.userAgent
	// 	const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
	// 	const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
	// 	if (u.indexOf("eshebao") != -1) {
	// 		if (isAndroid) {
	// 			;(window as any).eshebao.gotoBack()
	// 		} else if (isiOS) {
	// 			;(window as any).webkit.messageHandlers.eshebao.postMessage("gotoBack")
	// 		}
	// 	}
	// }

	mescrollInit(mescroll: any) {
		this.mescroll = mescroll // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
	}

	beforeDestroy() {
		this.mescroll.setBounce(true)
	}

	async mescrollData(mescroll: any) {
		console.log(22266777)
		await this.getSwiperData()
		await this.specialzone()
		await this.getGoodsData()
		await this.getFlashsaleList()
		this.$nextTick(() => {
			mescroll.endSuccess()
		})
	}
	// banner跳转
	gourl(item: any) {
		if (item.LinkUrl != "#") {
			const u: string = navigator.userAgent
			const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
			const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
			if (u.indexOf("eshebao") != -1) {
				if (isAndroid) {
					;(window as any).eshebao.gotoNextPage(item.LinkUrl)
				} else if (isiOS) {
					;(window as any).webkit.messageHandlers.gotoNextPage.postMessage(item.LinkUrl)
				}
			}
		}
	}
	gourl1() {
		const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
		if (u.indexOf("eshebao") != -1) {
			if (isAndroid) {
				;(window as any).eshebao.gotoNextPage(this.swiperListFirst.LinkUrl)
			} else if (isiOS) {
				;(window as any).webkit.messageHandlers.gotoNextPage.postMessage(
					this.swiperListFirst.LinkUrl
				)
			}
		}
	}
	// 商品列表
	getGoodsData() {
		this.$api.goodsList(this.goodsListParams).then((res: any) => {
			// console.log(res)
			this.goodsListData = res.data
		})
	}

	// 时间顺序
	async changeUpdateRank() {
		this.isDefault = true
		if (this.updateOrIntegral == false) {
			this.updateOrIntegral = true
			this.isUpdate = false
			this.isIntegral = true
			if (this.isUpdateRotate == false) {
				this.goodsListParams.Asc = 0
			} else {
				this.goodsListParams.Asc = 1
			}
			this.goodsListParams.SortType = 1
		} else {
			this.isUpdateRotate = !this.isUpdateRotate
			this.goodsListParams.SortType = 1
			if (this.goodsListParams.Asc == 0) {
				this.goodsListParams.Asc = 1
			} else {
				this.goodsListParams.Asc = 0
			}
		}
		await this.getGoodsData()
	}

	// 积分顺序
	async changeIntegralRank() {
		this.isDefault = true
		if (this.updateOrIntegral == true) {
			this.updateOrIntegral = false
			this.isUpdate = true
			this.isIntegral = false
			if (this.isIntegralRotate == false) {
				this.goodsListParams.Asc = 0
			} else {
				this.goodsListParams.Asc = 1
			}
			this.goodsListParams.SortType = 2
		} else {
			this.isIntegralRotate = !this.isIntegralRotate
			this.goodsListParams.SortType = 2
			if (this.goodsListParams.Asc == 0) {
				this.goodsListParams.Asc = 1
			} else {
				this.goodsListParams.Asc = 0
			}
		}
		await this.getGoodsData()
	}

	//轮播图
	getSwiperData() {
		// 我们先定义一个对象的属性，需要注意的是我们使用接口interface定义对象时，接口的首字母最好是大写
		interface Params {
			displayPage: string
		}
		const params: Params = {
			displayPage: "积分商城-首页banner",
		}
		this.$api.GetAdvertByDisplayPage(params).then((res: any) => {
			// console.log(res)
			if (res.ResponseObject != null) {
				this.swiperList = res.ResponseObject
				this.swiperListFirst = res.ResponseObject[0]
			}
		})
	}

	// 专区类型
	specialzone() {
		this.$api.specialzone().then((res: any) => {
			if (res.data != []) {
				this.zoneData = res.data.sort(this.compare("sort"))
			}
		})
	}

	toZoneCategories(val: any) {
		// console.log(val.categoryId)
		this.$router.push({
			name: "goodsClassifyList",
			query: { categoryId: val.categoryId, name: val.categoryName },
		})
	}

	compare(property: string) {
		return function (a: any, b: any) {
			const value1 = a[property]
			const value2 = b[property]
			return value1 - value2
		}
	}

	// 跳转到赚积分页面
	// toIntegral() {
	// 	goGainIntegral()
	// }
	// 获取限时促销列表
	getFlashsaleList() {
		this.$api.flashsalelist().then((res: any) => {
			// console.log(res)
			this.flashsalelist = res.data
		})
	}
	toDetail(item: any) {
		this.$router.push({
			path: `/goodsDetails/${item.id}`,
			query: {
				priceShowStyle: item.priceShowStyle,
			},
		})
	}
}
