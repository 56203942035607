
















import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import parts1 from "./parts1.vue"

@Component({
	components: {
		parts1
	}
})
export default class Default extends Vue {
	@Prop(String) specialZoneName!: String

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	// created() {}

	// mounted() {}
}
