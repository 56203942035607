












import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import parts1 from "./parts1.vue"
import parts2 from "./parts2.vue"

@Component({
	components: {
		parts1,
		parts2
	}
})
export default class zoneStyle2 extends Vue {
	@Prop(String) specialZoneName!: String

	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	// created() {
	// }

	// mounted() {
	// }
}
